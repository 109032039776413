/**
 * 搜索头
 */
export const searchFileds = [
  {
    type: "select",
    field: "service_group",
    label: "服务项",
    options: [],
    iw: 200
  },
  {
    type: "select",
    field: "account_type",
    label: "账户",
    options: [
      { value: "CONSUMER", label: "消费卡" },
      { value: "DEPOSIT", label: "储值账户" },
      { value: "GENERIC", label: "通用账户" }
    ],
    iw: 200
  },
  // {
  //   type: "select",
  //   field: "account_service",
  //   label: "服务",
  //   options: [
  //     { value: "GENERIC", label: "通用" },
  //     { value: "ETC", label: "ETC" },
  //     { value: "GAS", label: "油" },
  //     { value: "GEO", label: "轨迹" },
  //     { value: "OCR", label: "OCR" },
  //     { value: "SIGN", label: "电子签约" },
  //     { value: "SMS", label: "短信" },
  //     { value: "DATA_CHECK", label: "数据自检" },
  //     { value: "GPS", label: "车辆位置" },
  //     { value: "VERIFY", label: "认证" }
  //   ]
  // },
  {
    field: ["start_time", "end_time"],
    label: "时间",
    type: "dateRange",
    props: {
      placeholder: ["请选择时间", "请选择时间"],
      dateFormat: "YYYY/MM/DD"
    },
    iw: 320
  }
];
