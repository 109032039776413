export default function() {
  let resultHeight =
    document.documentElement.clientHeight -
    document.getElementsByClassName("result-table")[0].offsetTop -
    64;
  // resultHeight = document.documentElement.clientHeight - 88;
  // if (document.getElementsByClassName("search-header")[0]) {
  //   resultHeight =
  //     resultHeight -
  //     document.getElementsByClassName("search-header")[0].clientHeight;
  // }
  // if (document.getElementsByClassName("export-btn").length) {
  //   resultHeight =
  //     resultHeight -
  //     document.getElementsByClassName("export-btn")[0].clientHeight;
  // }
  // if (document.getElementsByClassName("ant-tabs-nav-wrap").length) {
  //   resultHeight =
  //     resultHeight -
  //     document.getElementsByClassName("ant-tabs-nav-wrap")[0].clientHeight;
  // }
  return resultHeight;
}
