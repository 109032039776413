<template>
  <page-layout>
    <!-- 消费账单 -->
    <div class="ma-24 d-flex align-end justify-space-between">
      <div></div>
      <a-button @click="exportVoucher" class="d-flex align-center">
        <Icon name="icondaochu1" width="15" height="15"></Icon>
        <span class="ml-8">导出</span>
      </a-button>
    </div>
    <!-- 搜索头 -->
    <search-header :schemaData="searchFileds" @getDataList="handleFormSearch" />
    <!-- 列表 -->
    <div class="mx-24 mt-12 bg-white">
      <p-table
        rowKey="id"
        dataKey="modal"
        :dataSource="dataSource"
        :columns="tableColumns"
      >
        <template #footer>
          <!-- <div class="w600 d-flex justify-space-start align-center">
            <a-button
              class="ml-12"
              @click="handleBatchExoort"
              :disabled="disabledBill"
              >确认账单</a-button
            >
            <p class="ml-12 mr-16">待确认账单金额：{{ totalAmount }}元</p>
          </div> -->
        </template>
      </p-table>
      <a-modal v-model:visible="visible" :width="300" @ok="handleConfirmOk">
        <p class="mt-30">
          共计{{ totalNumber }}条账单，{{
            totalAmount
          }}元，请核对无误后，单击【确定】确认全部账单。
        </p>
      </a-modal>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive, ref, onMounted } from "vue";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import { useRouter, useRoute } from "vue-router";
import { message } from "ant-design-vue";
import $await from "@/utils/await";
import { getColumns } from "./constants/table-config";
import useSearchForm from "@/hooks/UseSearchForm";
import useTableHeight from "@/hooks/useTableHeight";
import {
  useDailyQueryApi,
  useDailyConfirmApi,
  useSpendDailyExportExcalApi
} from "@/apis/financial";
import useSearch from "@/hooks/useSearch";
import { useEnumServiceApi } from "@/apis/enums";
import store from "@/store";

export default defineComponent({
  name: "spending-bills",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const visible = ref(false);
    const totalNumber = ref(0);
    const totalAmount = ref(0);
    const routeSearch = ref(true);
    const disabledBill = ref(false);
    const tableColumns = reactive(getColumns());
    const tableDataSource = reactive(getInitTable());
    const { filter, handleFormSearch } = useSearchForm(searchFileds);

    const dailyQuery = useDailyQueryApi();
    const dailyConfirm = useDailyConfirmApi();
    const spendDailyExport = useSpendDailyExportExcalApi();
    // table高度
    const tableHeight = ref();

    //服务选项列表
    const enumService = useEnumServiceApi();
    (async () => {
      let res = await enumService({ code: "billing_account_log_service" });
      useSearch(searchFileds, res, "service_group");
    })();

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        let data = {};
        if (!routeSearch.value) {
          data = {
            ...filter.value,
            size: tableDataSource.meta.pageSize,
            page: tableDataSource.meta.currentPageNo
          };
        } else {
          data = {
            account_id: route.query.id,
            account_type: route.query.type,
            size: tableDataSource.meta.pageSize,
            page: tableDataSource.meta.currentPageNo
          };
          routeSearch.value = false;
        }
        const res = await dailyQuery(data);

        // 如果返回列表中没有通用账户---禁用确认账单按钮
        const aa = res.list.filter(item => {
          return (
            item.account_type !== "GENERIC" &&
            item.invoice_status === "CONFIRMED"
          );
        });
        aa.length === res.list.length
          ? (disabledBill.value = true)
          : (disabledBill.value = false);

        totalNumber.value = res.total;

        return { records: res.list, total: res.total };
      }
    });

    // 操作列渲染逻辑
    tableColumns.forEach(item => {
      switch (item.dataIndex) {
        // 审核状态
        case "action":
          item.customRender = (_, row) => {
            if (store.getters.roles.includes("ServiceOrder")) {
              return (
                <div>
                  <a
                    class="color-primary  text-no-wrap"
                    style="color: #1684FB"
                    onClick={() => handleActionClick(row)}
                  >
                    订单明细
                  </a>
                </div>
              );
            }
          };
          break;
      }
    });

    // 跳转到服务订单--展示当前点击对应的数据
    const handleActionClick = row => {
      router.push({
        path: "/order/service-order",
        query: { tab: row.service_group, time: row.operating_time }
      });
    };

    // 点击确认订单，所在行数据消失---调用接口刷新页面
    const handleConfirmClick = async row => {
      let params = {
        ids: [row.id]
      };
      await dailyConfirm(params);
      getConsultingList();
    };

    // 导出数据
    const exportVoucher = async () => {
      // TODO  导出数据
      const data = {
        ...filter.value,
        export_flag: true,
        size: tableDataSource.meta.pageSize,
        page: tableDataSource.meta.currentPageNo
      };
      const [, res] = await $await(spendDailyExport(data));
      if (res) {
        message.success("导出成功，请前往下载中心查看");
      }
    };

    // 确认账单按钮
    const handleBatchExoort = async () => {
      // const selectedRows = tableDataSource.meta.selectedRows;
      // if (selectedRows.length !== 0) {
      //   totalNumber.value = selectedRows.length;
      //   let total = 0;
      //   selectedRows.map(item => {
      //     total += +item.money;
      //     totalNumber.value = selectedRows.length;
      //     totalAmount.value = total / 100;
      //   });
      //   visible.value = true;
      // } else {
      //   visible.value = true;
      // }
      visible.value = true;
    };

    const handleConfirmOk = async () => {
      let data = {
        select_all_flag: true
      };
      await dailyConfirm(data);
      message.success("账单确认成功!");
      visible.value = false;
      getConsultingList();
    };

    return {
      visible,
      routeSearch,
      totalAmount,
      totalNumber,
      tableColumns,
      searchFileds,
      disabledBill,
      exportVoucher,
      handleConfirmOk,
      getConsultingList,
      handleFormSearch,
      handleActionClick,
      handleBatchExoort,
      handleConfirmClick,
      dataSource: tableDataSource,
      tableHeight
    };
  }
});
</script>
<style lang="less" scoped>
/deep/.ant-form-item-label {
  background: #f7f8fd;
  border: none;
}
</style>
