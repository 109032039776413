import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/company");

// 财务管理
// 消费账单
export const useDailyQueryApi = basicApi({
  url: "/account/daily_bill/query",
  withCredentials: true
});

// 确认账单
export const useDailyConfirmApi = basicApi({
  url: "/account/daily_bill/confirm",
  withCredentials: true
});

// 消费账单导出
export const useSpendDailyExportExcalApi = basicApi({
  url: "/account/daily_bill/export",
  withCredentials: true
});

// 充值记录列表
export const useDailyRechargePageApi = basicApi({
  url: "/account/recharge/page",
  withCredentials: true
});

// 充值导出
export const useRechargeExportApi = basicApi({
  url: "/account/recharge/export",
  withCredentials: true
});

// 凭证临时请求url
export const useGenerateUrlApi = basicApi({
  url: "/oss/generate_url",
  withCredentials: true
});
