import { listToMap } from "@/constants/enum/basic";

//table列配置
export const getColumns = () => {
  const columns = [
    { type: "checkbox", hidden: true },
    {
      width: 80,
      type: "seq",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "时间",
      field: "operating_time",
      minWidth: 120,
      cellRender: "p-date"
    },
    {
      title: "服务项",
      dataIndex: "service_group_desc",
      minWidth: 120
    },
    {
      title: "消费金额(元)",
      field: "money",
      minWidth: 120,
      cellRender: "p-money"
    },
    {
      title: "账户",
      dataIndex: "account_type_desc",
      minWidth: 120
    },
    // {
    //   title: "状态",
    //   dataIndex: "invoice_status_desc",
    //   minWidth: 120
    // },
    {
      title: "操作",
      dataIndex: "action",
      width: 160,
      align: "left",
      fixed: "right"
    }
  ];
  return columns;
};

// 列表类型
export const tabList = [
  { id: 0, name: "未登记" },
  { id: 1, name: "已登记" }
];

export const typeMap = listToMap(tabList, "key", "tab");
